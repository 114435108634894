<template>
  <DoExamination>
    <template #nav="navSlotProps">
      <div class="header fc" :class="{isPad: app.isPad}">
        <h2 class="b">{{navSlotProps.paperObj.title}}</h2>
        <div class="fr header-footer">
          <div>习题数量：<span class="footer-section">{{navSlotProps.questionSize}}</span></div>
          <!-- <div class="fr paper-grade">试卷难度：<Star :val="navSlotProps.diff" /></div> -->
          <div>时间：<span class="footer-section last">{{navSlotProps.paperObj.test_duration}}分钟</span></div>
        </div>
      </div>
    </template>
    <template #answerSheetHeader="answerSheetSlotProps">
      <div class="fr sidebar-top">
        <img src="../assets/images/clock.png" alt="">
        <span>{{getOriginVal(answerSheetSlotProps.duration)}}</span>
      </div>
    </template>
    <template #answerSheetBtn="answerSheetSlotProps">
      <div class="tc">
        <span class="sidebar-submit-btn" @click="answerSheetSlotProps.showSubmitPaperDialog('提交试卷')">提交试卷</span>
      </div>
    </template>
  </DoExamination>
</template>

<script>
import DoExamination from 'comps/doExamination.vue'
import Star from 'comps/star'

export default {
  data() {
    return {
    }
  },
  components: {
    DoExamination,
    Star
  },
  inject: ['app'],
  methods: {
    // 显示倒计时
    getOriginVal(val) {
      let h = Math.floor(val/3600)
      let min = Math.floor(val % 3600 / 60)
      let s = Math.floor(val%3600%60)
      h = h > 9 ? h : '0' + h
      min = min > 9 ? min : '0' + min
      s = s > 9 ? s : '0' + s
      return `${h}:${min}:${s}`
    },
  },
}
</script>
<style lang="scss" scoped>
.header {
  min-width: 768px;
  padding: 30px 0;
  background: #FFFFFF;
  border-radius: 25px 25px 0px 0px;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  &.isPad {
    h2 {
      font-size: 24px;
    }
    .header-footer {
      font-size: 16px;
    }
  }
  h2 {
    font-size: 28px;
    color: #333;
  }
  .header-footer {
    font-size: 18px;
    color: #999999;
    margin-top: 25px;
    align-items: center;
    .paper-grade {
      align-items: center;
      margin-right: 50px;
    }
    .footer-section {
      color: #333;
      margin-right: 50px;
      &.last {
        margin-right: 0;
      }
    }
  }
}
.sidebar-top {
  height: 55px;
  border-bottom: 1px solid #ECECEC;
  align-items: center;
  padding-left: 20px;
  img {
    width: 23px;
    margin-right: 10px;
  }
  span {
    font-size: 20px;
    color: #EC5757;
  }
}
.sidebar-submit-btn {
  display: inline-block;
  margin-top: 34px;
  width: 104px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background: #68B8E6;
  border-radius: 21px;
  color: white;
  cursor: pointer;
}
</style>